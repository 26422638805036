export const packs = [
	{
		amount: 200,
		addition: 0,
		price: 2,
	},
	{
		amount: 500,
		addition: 50,
		price: 5,
	},
	{
		amount: 1000,
		addition: 150,
		price: 10,
	},
	{
		amount: 2000,
		addition: 300,
		price: 20,
	},
	{
		amount: 6000,
		addition: 1000,
		price: 50,
	},
];

export const exchangeToVnd = (amount: number) => amount * 20000;
