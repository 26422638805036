import React from 'react';

import { getBankQr } from '../../apis';
import socket from '../../utils/socket';
import { useEffect, useState } from 'react';

export function BankTransfer(body: { email: string; amount: number }) {
	const [qrcode, setQrcode] = useState('');
	const [loading, setLoading] = useState(true);
	const [finished, setFinished] = useState(false);

	useEffect(() => {
		getBankQr(body).then(({ data }) => {
			setQrcode(data.qrUri);
			setLoading(false);

			socket.emit('join-room', { room: data.code });
		});

		socket.on('bank-progress', (props) => {
			console.log('recevied', props);
			if (props.state === 'completed') setFinished(true);
		});

		return () => {
			socket.emit('leave-all-room');
		};
	}, [body]);

	return (
		<div>
			{!finished && (
				<>
					<div className="flex items-center w-fit mt-5">
						<svg
							className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
						>
							<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
							<path
								className="opacity-75"
								fill="currentColor"
								d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
							></path>
						</svg>
						<p>After you make transaction, wait about 2 minutes for the system to add crystal...</p>
					</div>
					{qrcode && <img className="mt-5" src={qrcode} alt="QrCode" />}
				</>
			)}
			{finished && <p className="mt-10 text-green-500">Crystals added to the account!</p>}
		</div>
	);
}
