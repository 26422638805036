import React from 'react';

import classNames from 'classnames';
import { FC } from 'react';

export const Pack: FC<{
	amount: number;
	addition: number;
	price: number;
	selected?: boolean;
	onClick?: () => void;
}> = ({ addition, amount, price, onClick, selected }) => {
	return (
		<div
			onClick={onClick}
			className={classNames(
				'w-full min-h-[74px] flex gap-3 rounded border border-zinc-300 py-3 px-4 cursor-pointer hover:shadow-[5px_5px_0_0_rgb(228,228,231)] hover:translate-x-[-5px] hover:translate-y-[-5px] transition duration-200',
				{ 'shadow-[5px_5px_0_0_rgb(228,228,231)] translate--[-5px] translate-y-[-5px]': selected }
			)}
		>
			<div className="border border-zinc-300 rounded-full h-fit min-w-[60px] py-2 px-3 text-center">${price}</div>
			<p>
				Get {amount} (+{addition}) crystals to do {amount + addition} translations!
			</p>
		</div>
	);
};
