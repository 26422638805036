import React from 'react';
import { completeStripe, getstripeCheckOutSession } from '../../apis';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { FormEventHandler, useState } from 'react';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

export function StripeTransfer(body: { email: string; amount: number }) {
	const [completed, setCompleted] = useState(false);

	const options: StripeElementsOptions = {
		mode: 'payment',
		amount: body.amount,
		currency: 'usd',
		appearance: {
			theme: 'night',
		},
	};

	return (
		<div className="my-5">
			{completed ? (
				<p className="text-green-500">Crystals added to the account!</p>
			) : (
				<Elements stripe={stripePromise} options={options}>
					<Form {...body} onCompleted={() => setCompleted(true)} />
				</Elements>
			)}
		</div>
	);
}

function Form({ onCompleted, ...body }: { email: string; amount: number; onCompleted: () => void }) {
	const stripe = useStripe();
	const elements = useElements();

	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);

	const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		setLoading(true);

		if (elements == null) {
			return;
		}

		// Trigger form validation and wallet collection
		const { error: submitError } = await elements.submit();
		if (submitError?.message) {
			// Show error to your customer
			setErrorMessage(submitError.message);
			return;
		}

		const {
			data: { client_secret: clientSecret },
		} = await getstripeCheckOutSession(body);

		const { error, paymentIntent } = await stripe!.confirmPayment({
			//`Elements` instance that was used to create the Payment Element
			elements,
			clientSecret,
			confirmParams: {
				return_url: 'https://example.com/order/123/complete',
			},
			redirect: 'if_required',
		});

		if (error) {
			// This point will only be reached if there is an immediate error when
			// confirming the payment. Show error to your customer (for example, payment
			// details incomplete)
			return setErrorMessage(error.message || '');
		}

		try {
			await completeStripe(paymentIntent.id);
			onCompleted();
		} catch (e: any) {
			setErrorMessage(e.response?.data?.message || '');
		}
		setLoading(false);
	};

	return (
		<form onSubmit={handleSubmit}>
			<PaymentElement />
			<button
				className="mt-4 w-fit py-2 px-6 cursor-pointer rounded bg-purple-500 hover:bg-purple-500/50 flex items-center transition duration-300"
				type="submit"
				disabled={!stripe || !elements || loading}
			>
				{loading && (
					<svg className="animate-spin mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
						<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
						<path
							className="opacity-75"
							fill="currentColor"
							d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
						></path>
					</svg>
				)}
				Pay
			</button>
			{/* Show error message to your customers */}
			{errorMessage && <div>{errorMessage}</div>}
		</form>
	);
}
