import axios from 'axios';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_DOMAIN + '/v1' });

export const validEmail = (email: string) => axiosInstance.get('auth/email-validate/' + email);

export const getBankQr = (body: { email: string; amount: number }) => axiosInstance.post('transaction/bank', body);

export const getstripeCheckOutSession = (body: { email: string; amount: number }) =>
	axiosInstance.post('transaction/stripe', body);

export const completeStripe = (paymentId: string) =>
	axiosInstance.get('/transaction/stripe-complete?paymentId=' + paymentId);
