import React from 'react';

import { Pack } from './components/pack';
import { packs } from './utils';
import classNames from 'classnames';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import * as apis from './apis';
import { BankTransfer } from './components/paymentform/bank';
import { StripeTransfer } from './components/paymentform/stripe';

function App() {
	const [paymentMethod, setPaymentMethod] = useState('');
	const [pack, setPack] = useState(0);
	const [user, setUser] = useState<{ email: string; crystal: number; verified: boolean } | null>(null);
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');

	const handlePaymentSelect = (value: string) => {
		if (!email) return setEmailError('Please enter your email first');
		console.log(value);
		setPaymentMethod(value);
	};

	const handleValidEmail = () => {
		if (!yup.string().email().required().isValidSync(email)) return setEmailError('Invalid email');

		apis
			.validEmail(email)
			.then(({ data }) => {
				setUser(data);
			})
			.catch((e) => {
				console.log(e);
				setEmailError(e.response?.data?.message);
			});
	};

	useEffect(() => {
		if (paymentMethod === 'stripe') {
		}
	}, [paymentMethod]);

	return (
		<main className="pt-8 min-h-screen">
			<div className="mx-auto w-[500px] rounded p-6 border border-zinc-0">
				<div className="mb-8">
					<h2 className="text-lg mb-4">Choose a Pack</h2>
					<div className="flex flex-col gap-4">
						{packs.map((p, i) => (
							<Pack
								key={i}
								{...p}
								selected={pack === p.amount}
								onClick={() => {
									setPack(p.amount);
									setPaymentMethod('');
								}}
							/>
						))}
					</div>
				</div>
				<div className="mb-8">
					{!user && (
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleValidEmail();
							}}
						>
							<h2 className="text-lg mb-4">Set target account</h2>
							<input
								type="email"
								className={classNames(
									'w-full bg-zinc-700 rounded outline-none  focus:border-2 focus:border-pink-500 py-2 px-4',
									{
										'border border-zinc-600': !emailError,
										'border-2 border-red-500': !!emailError,
									}
								)}
								placeholder="Enter your email"
								onChange={(e) => {
									if (emailError) setEmailError('');
									setEmail(e.currentTarget.value);
								}}
								value={email}
								onBlur={() => handleValidEmail()}
							/>
							{emailError && <p className="text-red-500 mt-1">{emailError}</p>}
							<button type="submit" className="hidden"></button>
						</form>
					)}
					{user && (
						<>
							<h2 className="text-lg mb-4">Crystal will be sent to this account</h2>
							<div className="flex gap-2">
								<p className="sm:w-2/12">Email:</p>
								<p className="sm:w-10/12">{user.email}</p>
							</div>
							<div className="flex gap-2">
								<p className="sm:w-2/12">Crystals:</p>
								<p className="sm:w-10/12">{user.crystal}</p>
							</div>
							<button
								className={
									'w-fit py-1 px-2 mt-4 cursor-pointer rounded border border-pink-500 text-pink-500 hover:bg-pink-500/50  transition duration-300'
								}
								onClick={() => {
									setUser(null);
									setEmail('');
									setPaymentMethod('');
								}}
							>
								Change
							</button>
						</>
					)}
				</div>
				<div className="mb-8">
					<h2 className="text-lg mb-4">Choose a payment method</h2>
					<div className="flex flex-col gap-3">
						<button
							className={classNames(
								'w-fit py-2 px-4 cursor-pointer rounded border border-pink-500 text-pink-500 hover:bg-pink-500/50 transition duration-300',
								{
									'bg-pink-500 text-zinc-800 ': paymentMethod === 'paypal',
								}
							)}
							onClick={() => handlePaymentSelect('stripe')}
						>
							Stripe
						</button>
						{paymentMethod === 'stripe' && <StripeTransfer amount={pack} email={email} />}
						<button
							className={classNames(
								'w-fit py-2 px-4 cursor-pointer rounded border border-pink-500 text-pink-500 hover:bg-pink-500/50 transition duration-300',
								{
									'bg-pink-500 text-zinc-800 ': paymentMethod === 'banktransfer',
								}
							)}
							onClick={() => handlePaymentSelect('banktransfer')}
						>
							Bank Transfer (Vietnam only)
						</button>
						{paymentMethod === 'banktransfer' && <BankTransfer amount={pack} email={email} />}
					</div>
				</div>
			</div>
		</main>
	);
}

export default App;
